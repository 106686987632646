import { DesignType, IDesign } from '../../../store/api/admin/types/models';
import Carousel from 'react-multi-carousel';
import React from 'react';
import { Box } from '@mui/material';

export enum StickerType {
  RECTANGLE = 'RECTANGLE',
  BANNER = 'BANNER',
  OTHER = 'OTHER'
}

export const generateStickerData = (
  id: string,
  type: string, 
  img: string, 
  key: string, 
  previewImg: string, 
  width: number, 
  height: number
): ISticker => ({
  id,
  type, 
  img, 
  key,
  previewImg,
  width,
  height
} as ISticker)

export const transformToSticker = (tagDesign: IDesign) => {
  if (tagDesign.name.startsWith(StickerType.RECTANGLE)) {
    return generateStickerData(tagDesign.id, StickerType.RECTANGLE, tagDesign.fileUrl, tagDesign.name, tagDesign.fileUrl, 142, 85)
  } else if (tagDesign.name.startsWith(StickerType.BANNER)) {
    return generateStickerData(tagDesign.id, StickerType.BANNER, tagDesign.fileUrl, tagDesign.name, tagDesign.fileUrl, 70, 117)
  } else {
    return generateStickerData(tagDesign.id, StickerType.OTHER, tagDesign.fileUrl, tagDesign.name, tagDesign.fileUrl, 85, 85)
  }
}


export interface ISticker {
  id: string,
  type: StickerType,
  img: string,
  key: DesignType,
  previewImg: string,
  width: number, 
  height: number
}

export const carouselProps = {
  
  responsive: {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
};


interface StickerCarouselProps {
  children: React.ReactNode,
  type: StickerType | string
}

export const StickerCarousel: React.FC<StickerCarouselProps> = ({
  children,
  type
}) => {

  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    setRender(true);
    setTimeout(() => setRender(false), 100);
  }, [type])

  return (
    <>
      {!render
        ? <Carousel 
            infinite={false}
            autoPlay={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 300 },
                items: type === StickerType.RECTANGLE 
                ? 3.5 : type === StickerType.OTHER 
                ? 5 : 7
              }
            }} 
          >
            {children}
          </Carousel>
        : <Box height={134} minHeight={134} />
      }
    </>
  )
}